import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I didn’t want to do it, but I had to. All my morals were against that sort of thing, but you have to do what you have to do to survive in this harsh world. Let me take you back to three months ago. At that time I had a challenging life, but no different than anyone else living in our neighbourhood.`}</p>
    <p>{`My five siblings and I live in a small shack in a small Sudanese city with our mother. My father died when I was 7 years old due to cancer. I don’t remember him much, so honestly, I don’t really miss him. Anyway, let’s talk about how a usual day would look back then. First, I would wake up in the morning around 5 AM and get ready for school. Then, around 5:20 AM I would leave home with my siblings to go to school. Getting to school would take about an hour of walking. I didn’t go to school before, my mother only decided to let me and my siblings go to school two years ago. Anyway, at two o’clock I would go to my local market, skipping two of my last classes. I would do this because I had to work at the market to sell plastic bags. This is my responsibility since I’m the oldest out of six children. I would get home exhausted, at around 6:20 PM. After that, I would go to sleep and repeat the same thing the next day.`}</p>
    <p>{`So, around mid-march, after a hard few hours of working, selling a bunch of old plastic bags, I came home and sat down on the floor next to my mother. I talked about my day, and she talked about her job as a cleaner in a restaurant. I could feel something was wrong. While we talked, she coughed a lot, and her voice was raspy. But I didn’t pay much attention to that. What I did pay attention to was when she coughed one time so hard that a few drops of blood dribbled down her mouth and onto the carpet. I was frightened! Why was this happening? She quickly said it was nothing, and said she was fine and went to the bathroom to rinse her mouth. I’m no scientist, but I could see that she was sick. Without me asking, my mother repeated that she is fine and will go to work the next day.`}</p>
    <p>{`After a couple of days, it seemed like she was still sick, but nothing too bad, so my worries went away a little. One day I woke up to get ready for school, and since my mother is usually gone for work before I even wake up, you can understand how surprised I was to see her fast asleep in our big sleeping room. I shook her and when she woke up she could barely speak. She whispered to me that she couldn’t move. That sentence made me very worried. I told her we should go to the hospital, but she called me silly and said that we don’t have the money to go to the hospital. I realized she was right, I have never seen the inside of a hospital in my life. So, I asked her what should we do? She said that we should just wait for her to get better. I knew this would not work, so I told her to go back to sleep, and woke up my siblings and told them about mother’s situation.`}</p>
    <p>{`I knew that we had to get money somehow very quick to get our mother to the hospital! But we didn’t know how. Working for someone is out of the option, because in Sudan you should know that if you’re a child and working for someone, the boss will pay you little to nothing; you would get paid better if you beg on the street! We thought for a while but none of us could think of anything. We finally agreed that one of my brothers would sell some plastic bags, while the others begged in the richest neighbourhoods, and I would try to figure out how to get more money. We would all get back together in two hours. So off I went trying to find ways to get money. I first went into shops asking for small amounts of money for my mother. Overall from that scavenge, I got 5 Sudanese pounds. That was nowhere near what I should get for my mother to get into the hospital. A bad idea came to my mind, but I quickly swatted that away. So, after that, I tried ringing the doorbell of fancy-looking houses to see if they could spare some money for me. After a few houses, not surprisingly, I didn’t get any money. The rich Sudanese people hate lower class people like me and my family. Two hours had finally passed, we all grouped together back at our house. Mother looked even worse than before. My brother that was selling plastic bags got 4 pounds, my siblings that begged got 5 pounds, and I also only got 5 pounds. So we had 14 pounds in total, that was nowhere near enough!`}</p>
    <p>{`We parted again and I was desperate for ideas. Then it struck me, I knew what I had to do - I had to steal. My mother had always told me not to steal, it was against our culture, and God wouldn’t be pleased with that. But I had to do it. I decided to go into a middle-class person’s house and do the job as quiet as a mouse. I picked the house at random. I jumped over the gate, and snuck through an open window. There was a baby sitter and a baby on the floor above. But I quickly looked through the drawers and found the jackpot, 500 pounds, and a gold watch. I reached for it, my mind told me, no, but my body said yes. I finally decided that I need to do this, and I took the money. When I was exiting the house, I saw a picture of two parents and a baby. Seeing this made me upset, seeing the happy faces of the people I am robbing from. But I couldn’t have that mindset. I jumped back over their gate and ran for my life. I tried to run past the grey cloud that was in my head, but it kept up with me. I went up to random people to ask them if they wanted to buy the gold watch I stole, one of them bought it for 70 pounds. I got home and woke my mother up. When she finally got up, I carried her to the nearest rich neighbourhood hospital. They told me that they don’t take my type of people (poor people), but I shoved a wad of cash on the counter, and they didn’t take much time to rush me and my mother into the ER.`}</p>
    <p>{`When I knew she was safe lying down, they escorted me out of the room. I went home and told my siblings what had happened. But I ended up lying to them; I told my young siblings that a nice man gave me the money. After a few weeks, my mother got better, and we were allowed to see her. When I saw her I immediately hugged her, and we looked at each other for a brief moment. We never spoke about how I got that money, but that one glance we shared was almost like having a whole discussion on the matter. The grey cloud still hangs over my head, even to this day, but in the end, it was worth it, to save my mother.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      